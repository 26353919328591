import * as React from 'react';
import { isEmpty } from '../../utils';

export interface IColorProps {
  color?: string;
}

import css from './styles.module.css';

const Stroke: React.FC<IColorProps> = ({ color }) => (
  <div className={css.stroke}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" preserveAspectRatio="xMidYMin meet">
      <rect
        className={css.first}
        width="1000%"
        height="1000%"
        fill={(!isEmpty(color) && color) || '#72f887'}
        transform="rotate(45 100 50) translate(-5 0)"
      ></rect>
      <rect
        className={css.second}
        width="1000%"
        height="1000%"
        fill="#ffffff"
        transform="rotate(45 100 50) translate(-3 0)"
      ></rect>
    </svg>
  </div>
);

export default Stroke;
