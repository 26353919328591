import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { fetchStatus } from '../../store/app/actions';
import { IStoreStatus } from '../../store/app/types';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from './styles.module.css';

// TODO: #126 (https://github.com/vib-gmbh/vib-connect-node/issues/126)
export const isAuthorized = (status: IStoreStatus, loggedIn? = false): any => {
  if (status.authorized === true) {
    return true;
  }

  if (status.authorized === false && loggedIn === true) {
    return <Redirect to="/logout" />;
  }

  if (status.authorized === false && loggedIn === false) {
    return false;
  }
};

// TODO: #125 (https://github.com/vib-gmbh/vib-connect-node/issues/125)
const Status: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.app.status);

  useEffect(() => {
    const ts = Date.now();
    if (state.timestamp === null || ts - state.timestamp > 10000) {
      dispatch(fetchStatus());
    }
  }, [state, dispatch]);

  if (isAuthorized(state) === false) {
    return <section className={css.status}>{t('status.loginMsg')}</section>;
  }

  return <section></section>;
};

export default Status;
