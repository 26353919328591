import React from 'react';
import Welcome from '../../components/Welcome';
import Imprint from '../../components/Imprint';
import { useLocation } from 'react-router';

import css from './styles.module.css';

interface IPageProps {
  component?: string;
}

const Page: React.FC<IPageProps> = ({ component }) => {
  const location = useLocation();
  let content = null;
  const showComponent = location.state?.component || component || '';

  switch (showComponent) {
    case 'imprint':
      content = <Imprint />;
      break;
    default:
      content = <Welcome />;
  }

  return <section className={css.wrapper}>{content}</section>;
};

export default Page;
