import { combineReducers, Reducer, CombinedState } from 'redux';
import app from './app/reducer';
import data from './data/reducer';

import { AppState, Action } from './app/types';
import { DataState } from 'store/data/types';

const createRootReducer = (): Reducer<CombinedState<{ app: AppState; data: DataState }>, Action> =>
  combineReducers({
    app,
    data,
  });

const rootReducer = createRootReducer();

export type RootState = ReturnType<typeof rootReducer>;

export default createRootReducer;
