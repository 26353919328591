import React, { useEffect } from 'react';
import { useCountUp } from 'react-countup';
import { DescribeArc, Scale, ISODateStringTime, ISODateStringDate } from '../../utils';
import css from './styles.module.css';

import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const Gauge: React.FC<any> = (props) => {
  const { colordark } = useSelector((state: RootState) => state.app.config);

  const meta = props.data.meta.mdata;
  const value = props.data.gauge.value;
  const timestamp = props.data.gauge.sourcetimestamp;

  const minValue = meta.GaugeScaleMin;
  const maxValue = meta.GaugeScaleMax;
  const GaugeSector1Limit = meta.GaugeSector1Limit;
  const GaugeSector2Limit = meta.GaugeSector2Limit;
  const GaugeSector3Limit = meta.GaugeSector3Limit;
  const GaugeSector4Limit = meta.GaugeSector4Limit;
  const language = useSelector((state: RootState) => state.app.locale);

  const countUpValue = Number(Number(value).toFixed(meta.DecimalAccuracy));

  const { countUp, update } = useCountUp({
    decimals: meta.DecimalAccuracy,
    end: countUpValue,
    duration: 0.15,
  });

  useEffect(() => {
    let isCanceled = false;

    if (isCanceled) {
      return;
    }

    if (countUpValue !== countUp) {
      if (!isCanceled) {
        update(countUpValue);
      }
    }

    return () => {
      isCanceled = true;
    };
  }, [countUpValue, countUp, update]);

  return (
    <div className={css.instrument}>
      <svg viewBox="0 0 300 300">
        <circle fill={colordark || '#263d42'} cx="150" cy="150" r="150" />
        <path fill="none" stroke="#516468" strokeWidth="26" d={DescribeArc(150, 150, 92, -120, 120)} />
        <path
          fill="none"
          stroke={
            GaugeSector1Limit
              ? countUp < GaugeSector1Limit
                ? meta.GaugeSector1Color || '#72f887'
                : GaugeSector2Limit
                ? countUp < GaugeSector2Limit
                  ? meta.GaugeSector2Color || meta.GaugeSector1Color || '#72f887'
                  : GaugeSector3Limit
                  ? countUp < GaugeSector3Limit
                    ? meta.GaugeSector3Color || meta.GaugeSector2Color || meta.GaugeSector1Color || '#72f887'
                    : GaugeSector4Limit
                    ? countUp < GaugeSector4Limit
                      ? meta.GaugeSector4Color ||
                        meta.GaugeSector3Color ||
                        meta.GaugeSector2Color ||
                        meta.GaugeSector1Color ||
                        '#72f887'
                      : GaugeSector4Limit
                      ? countUp >= GaugeSector4Limit
                        ? meta.GaugeSector5Color ||
                          meta.GaugeSector4Color ||
                          meta.GaugeSector3Color ||
                          meta.GaugeSector2Color ||
                          meta.GaugeSector1Color ||
                          '#72f887'
                        : '#72f887'
                      : meta.GaugeSector4Color ||
                        meta.GaugeSector3Color ||
                        meta.GaugeSector2Color ||
                        meta.GaugeSector1Color ||
                        '#72f887'
                    : meta.GaugeSector4Color ||
                      meta.GaugeSector3Color ||
                      meta.GaugeSector2Color ||
                      meta.GaugeSector1Color ||
                      '#72f887'
                  : meta.GaugeSector3Color || meta.GaugeSector2Color || meta.GaugeSector1Color || '#72f887'
                : meta.GaugeSector2Color || meta.GaugeSector1Color || '#72f887'
              : meta.GaugeSector1Color || '#72f887'
          }
          strokeWidth="26"
          d={DescribeArc(
            150,
            150,
            92,
            -120,
            countUp <= minValue ? -120 : countUp >= maxValue ? 120 : Scale(countUp, minValue, maxValue),
          )}
        />

        {GaugeSector1Limit ? (
          <>
            <g transform={`rotate(${Scale(minValue, minValue, maxValue)} 150 150)`}>
              <text fill="#ffffff" transform="translate(150 28)" textAnchor="middle">
                {minValue.toString()}
              </text>
            </g>
            <path
              fill="none"
              stroke={meta.GaugeSector1Color ? meta.GaugeSector1Color : '#516468'}
              strokeWidth="5"
              d={DescribeArc(150, 150, 112, -120, Scale(GaugeSector1Limit, minValue, maxValue))}
            />
            <g transform={`rotate(${Scale(GaugeSector1Limit, minValue, maxValue)} 150 150)`}>
              <text fill="#ffffff" transform="translate(150 28)" textAnchor="middle">
                {GaugeSector1Limit.toString()}
              </text>
            </g>
          </>
        ) : (
          <g transform={`rotate(${Scale(minValue, minValue, maxValue)} 150 150)`}>
            <text fill="#ffffff" transform="translate(150 28)" textAnchor="middle">
              {minValue.toString()}
            </text>
          </g>
        )}

        {GaugeSector2Limit && (
          <>
            <path
              fill="none"
              stroke={meta.GaugeSector2Color ? meta.GaugeSector2Color : meta.GaugeSector1Color || '#516468'}
              strokeWidth="5"
              d={DescribeArc(
                150,
                150,
                112,
                Scale(GaugeSector1Limit, minValue, maxValue),
                Scale(GaugeSector2Limit, minValue, maxValue),
              )}
            />
            <g transform={`rotate(${Scale(GaugeSector2Limit, minValue, maxValue)} 150 150)`}>
              <text fill="#ffffff" transform="translate(150 28)" textAnchor="middle">
                {GaugeSector2Limit.toString()}
              </text>
            </g>
          </>
        )}

        {GaugeSector3Limit && (
          <>
            <path
              fill="none"
              stroke={
                meta.GaugeSector3Color
                  ? meta.GaugeSector3Color
                  : meta.GaugeSector2Color || meta.GaugeSector1Color || '#516468'
              }
              strokeWidth="5"
              d={DescribeArc(
                150,
                150,
                112,
                Scale(GaugeSector2Limit, minValue, maxValue),
                Scale(GaugeSector3Limit, minValue, maxValue),
              )}
            />
            <g transform={`rotate(${Scale(GaugeSector3Limit, minValue, maxValue)} 150 150)`}>
              <text fill="#ffffff" transform="translate(150 28)" textAnchor="middle">
                {GaugeSector3Limit.toString()}
              </text>
            </g>
          </>
        )}

        {GaugeSector4Limit && (
          <>
            <path
              fill="none"
              stroke={
                meta.GaugeSector4Color
                  ? meta.GaugeSector4Color
                  : meta.GaugeSector3Color || meta.GaugeSector2Color || meta.GaugeSector1Color || '#516468'
              }
              strokeWidth="5"
              d={DescribeArc(
                150,
                150,
                112,
                Scale(GaugeSector3Limit, minValue, maxValue),
                Scale(GaugeSector4Limit, minValue, maxValue),
              )}
            />
            <g transform={`rotate(${Scale(GaugeSector4Limit, minValue, maxValue)} 150 150)`}>
              <text fill="#ffffff" transform="translate(150 28)" textAnchor="middle">
                {GaugeSector4Limit.toString()}
              </text>
            </g>
          </>
        )}

        <path
          fill="none"
          stroke={
            meta.GaugeSector5Color ||
            meta.GaugeSector4Color ||
            meta.GaugeSector3Color ||
            meta.GaugeSector2Color ||
            meta.GaugeSector1Color ||
            '#516468'
          }
          strokeWidth="5"
          d={DescribeArc(
            150,
            150,
            112,
            Scale(
              GaugeSector4Limit || GaugeSector3Limit || GaugeSector2Limit || GaugeSector1Limit || minValue,
              minValue,
              maxValue,
            ),
            Scale(maxValue, minValue, maxValue),
          )}
        />

        <g transform={`rotate(${Scale(maxValue, minValue, maxValue)} 150 150)`}>
          <text fill="#ffffff" transform="translate(150 28)" textAnchor="middle">
            {maxValue.toString()}
          </text>
        </g>

        <text className={css.value} fill="#ffffff" transform="translate(150 163)" textAnchor="middle">
          {countUp}
        </text>

        <text className={css.unit} fill="#ffffff" transform="translate(150 214)" textAnchor="middle">
          {meta.Unit}
        </text>

        <text className={css.timestamp} fill="#ffffff" transform="translate(150 220)" textAnchor="middle">
          <tspan x="0" dy="1.2em">
            {ISODateStringDate(new Date(timestamp))}
          </tspan>
          <tspan x="0" dy="1.2em">
            {ISODateStringTime(new Date(timestamp))}
          </tspan>
        </text>
      </svg>
      <div className={css.instrumentLabel}>
        <h3>{language === 'fr_FR' ? meta.LabelFrench : language === 'it_IT' ? meta.LabelItalian : meta.LabelGerman}</h3>
      </div>
    </div>
  );
};

export default Gauge;
