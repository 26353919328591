import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../../config/config.json';
import { isEmpty } from '../../utils';

import { IInventory, IGauges } from 'store/data/types';

export const ActionTypes = {
  SETINVENTORY: 'data/set-inventory',
  SETGAUGES: 'app/set-gauges',
};

export const fetchInventory = createAsyncThunk(ActionTypes.SETINVENTORY, async () => {
  if (isEmpty(config.apibaseuri)) {
    return {
      exception: {
        message: "Can't load status",
        error: 'Unknown API Base URI in Config.',
        authorized: false,
        timestamp: Date.now(),
      },
    };
  }
  const response = await axios.get('https://vanotracker.ch/api/inventory');
  const data = response.data;
  data.timestamp = Date.now();
  return data;
});

export const fetchGauges = createAsyncThunk(ActionTypes.SETGAUGES, async (uuid: string) => {
  if (isEmpty(config.apibaseuri)) {
    return {
      exception: {
        message: "Can't load gauges",
        error: 'Unknown API Base URI in Config.',
        authorized: false,
        timestamp: Date.now(),
      },
    };
  }
  const response = await axios.get(`https://vanotracker.ch/api/gauges/${uuid}`);
  const data = response.data;
  data.timestamp = Date.now();
  return data;
});

export const setInventory = (inventory: IInventory) => ({
  type: ActionTypes.SETINVENTORY,
  payload: inventory,
});

export const setGauges = (gauges: IGauges) => {
  return {
    type: ActionTypes.SETGAUGES,
    payload: gauges,
  };
};
