import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './styles.module.css';

const Button = (props) => {
  return props.link ? (
    props.external ? (
      <a href={props.link} target="_blank" rel="noopener noreferrer" className={`${Styles.button}`}>
        {props.text}
      </a>
    ) : (
      <Link
        to={
          !props.disableWhileFading
            ? {
                pathname: props.link,
                state: { locationState: props.locationState },
              }
            : {}
        }
        className={`${Styles.button} ${props.add ? Styles.add : ''} ${props.abort ? Styles.abort : ''} ${
          props.disabled ? Styles.disabled : ''
        }`}
      >
        {props.text}
      </Link>
    )
  ) : props.type === 'submit' && props.value ? (
    <input
      type={props.type}
      value={props.value}
      disabled={props.disabled}
      className={`${Styles.button} ${props.isSaving ? Styles.disabled : ''} ${props.disabled ? Styles.disabled : ''}`}
    />
  ) : props.onClick ? (
    <button
      className={`${Styles.button} ${props.danger ? Styles.danger : ''} ${props.abort ? Styles.abort : ''} ${
        props.add ? Styles.add : ''
      }`}
      onClick={props.onClick}
      disabled={props.disableWhileFading}
    >
      {props.text}
    </button>
  ) : (
    <span className={`${Styles.button} ${Styles.disabled}`}>{props.text}</span>
  );
};

export default Button;
