/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
const routes = {
  home: '/',
  login: '/login', // auth0
  logout: '/logout', // auth0
  callback: '/callback', // auth0
  admin: '/a/:target',
  inventory: '/i/:machines',
  gauges: '/g/:uuid',
  page: '/p/:title',
};

const getRoute = (path: string, params?: { [key: string]: string | number }, routesConfig: any = routes) =>
  path.split('.').reduce((routeBranch: any, pathItem: string) => {
    if (routeBranch && routeBranch[pathItem]) {
      const route = routeBranch[pathItem];
      if (typeof route === 'string') {
        if (!params || typeof params === 'undefined') {
          return route;
        }

        return Object.entries(params).reduce((replaced, [key, value]) => {
          return replaced.replace(`:${key}`, String(value));
        }, route);
      }
      return routeBranch[pathItem];
    }
  }, routesConfig);

export { getRoute, routes };
