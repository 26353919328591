import { produce } from 'immer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionTypes, fetchInventory, fetchGauges } from 'store/data/actions';
import { Action } from 'store/app/types';
import { DataState } from 'store/data/types';

import metricmetadata from './metricmetadata.json';

// TODO: #124 (https://github.com/vib-gmbh/vib-connect-node/issues/124)
export const initialState = Object.freeze<DataState>({
  inventory: {
    org: [],
    grp: [],
    machines: [],
    mapping: [],
    timestamp: null,
  },
  gauges: {
    indicators: [],
    timestamp: null,
  },
  metricmetadata: metricmetadata,
  isLoading: false,
});

/*
 * @internal
 * Fix https://github.com/vib-gmbh/vib-connect-node/issues/98
 */
const dataSlice = createSlice({
  name: 'status',
  initialState,
  reducers: (state: DataState = initialState, action: Action): DataState =>
    produce(state, (draft) => {
      switch (action.type) {
        case ActionTypes.SETINVENTORY: {
          draft.inventory = action.payload;
          return;
        }

        case ActionTypes.SETGAUGES: {
          draft.gauges = action.payload;
          return;
        }
      }
    }),
  extraReducers(builder) {
    builder
      .addCase(fetchInventory.pending, (state: DataState, action: PayloadAction<any>) => {
        state.inventory = action.payload;
        state.isLoading = true;
      })
      .addCase(fetchInventory.fulfilled, (state: DataState, action: PayloadAction<any>) => {
        state.inventory = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchInventory.rejected, (state: DataState, action: PayloadAction<any>) => {
        state.inventory = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchGauges.pending, (state: DataState, action: PayloadAction<any>) => {
        state.gauges = action.payload;
        state.isLoading = true;
      })
      .addCase(fetchGauges.fulfilled, (state: DataState, action: PayloadAction<any>) => {
        state.gauges = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchGauges.rejected, (state: DataState, action: PayloadAction<any>) => {
        state.gauges = action.payload;
        state.isLoading = false;
      });
  },
});

export default dataSlice.reducer;
