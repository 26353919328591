import * as React from 'react';
import { Link } from 'react-router-dom';
import { IInventory } from '../../store/data/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { ISODateStringDate, ISODateStringTime } from '../../utils';

import css from './styles.module.css';

interface IMachineProps {
  org: string;
  data: IInventory;
}

// TODO: #129 (https://github.com/vib-gmbh/vib-connect-node/issues/129)
const Machines: React.FC<IMachineProps> = ({ org, data }) => {
  if (!org || data.machines?.length === 0) {
    return '';
  }

  const { colorcode } = useSelector((state: RootState) => state.app.config);

  const rawMappingUUIDs = [...data.mapping].map((m) => m.machine_uuid);
  const rawMachineUUIDs = [...data.machines].map((m) => m.machine_uuid);

  // const leftoversUUIDs = rawMappingUUIDs.filter(
  //   (mappingitem) => !rawMachineUUIDs.some((machineitem) => machineitem === mappingitem),
  // );

  const leftoversUUIDs = rawMachineUUIDs.filter(
    (machineitem) => !rawMappingUUIDs.some((mappingitem) => machineitem === mappingitem),
  );

  // filter out the groups of the current organization
  const groups = data.grp?.filter((grp) => grp.organization_uuid === org);

  // create and fill array with UUIDs of machines in the same order as groups
  const groupedMachineUUIDs = [];

  groups?.forEach((grp, i) => {
    groupedMachineUUIDs[i] = [];
    data.mapping?.forEach((m, j) => {
      if (grp.uuid === m.subgroup_uuid) {
        groupedMachineUUIDs[i][groupedMachineUUIDs[i].length] = m.machine_uuid;
      }
    });
  });

  // bring groupedMachines and leftovers together
  groupedMachineUUIDs[groupedMachineUUIDs.length] = leftoversUUIDs;

  // Update group names for leftovers
  groups[groups.length] = { groupname: 'Global' };

  // create copy of machines and group machines with uuids from groupedMachineUUIDs
  const machines = [];

  groupedMachineUUIDs.map((grp, i) => {
    machines[i] = [];
    groupedMachineUUIDs[i].map((uuid, j) => {
      const machine = data.machines?.find(({ machine_uuid }) => machine_uuid === uuid);
      if (machine !== undefined) {
        if (machine.holder_uuid === org) {
          machines[i][machines[i].length] = machine;
        }
      }
    });
  });

  return (
    <div key={org}>
      {groups.map((grp, i) => (
        <div key={grp.uuid}>
          {machines[i].length !== 0 && (
            <div key={`${grp.uuid}-${i}`}>
              <h3>{grp.groupname}</h3>
              {machines[i].map((machine) => (
                <div key={machine.machine_uuid} className={css.machine}>
                  <div className={`${css.machineName} bold`}>{machine.machinename}</div>
                  <div
                    className={css.status}
                    style={{ backgroundColor: (machine.suspected_online_status && '#3FBF3F') || '#BF3F3F' }}
                  >
                    &nbsp;
                  </div>
                  <div className={css.timestamp}>
                    {ISODateStringDate(new Date(machine.latest_sourcetimestamp))},&nbsp;
                    {ISODateStringTime(new Date(machine.latest_sourcetimestamp))} Uhr
                  </div>
                  <div>
                    <Link
                      className={css.readMore}
                      style={{ color: colorcode }}
                      to={{
                        pathname: `/g/${machine.machine_uuid}`,
                        state: {
                          machine: machine.machine_uuid,
                          name: machine.machinename,
                          latest: `${ISODateStringDate(new Date(machine.latest_sourcetimestamp))}, ${ISODateStringTime(
                            new Date(machine.latest_sourcetimestamp),
                          )} Uhr`,
                          maps: [
                            {
                              lat: machine.gnss_latitude,
                              alt: machine.gnss_altitude,
                              lng: machine.gnss_longitude,
                              MachineName: machine.machinename,
                              MachineId: machine.machine_uuid,
                              GoogleMapsURL: `http://maps.google.ch/maps?f=q&hl=de&geocode=&q=${machine.gnss_latitude},${machine.gnss_longitude}`,
                              ServerRequestTimeStamp: machine.latest_sourcetimestamp,
                            },
                          ],
                        },
                      }}
                    >
                      Detail
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Machines;
