import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../../config/config.json';
import { isEmpty } from '../../utils';

import { IStoreStatus, Locale } from './types';

export const ActionTypes = {
  SETLOCALE: 'app/set-locale',
  SETSTATUS: 'app/set-status',
};

export const fetchStatus = createAsyncThunk(ActionTypes.SETSTATUS, async () => {
  if (isEmpty(config.apibaseuri)) {
    return {
      message: "Can't load status",
      error: 'Unknown API Base URI in Config.',
      authorized: false,
      timestamp: Date.now(),
    };
  }
  const response = await axios.get('https://vanotracker.ch/validate');
  const data = response.data;
  data.timestamp = Date.now();
  return data;
});

export const setLocale = (locale: Locale) => ({
  type: ActionTypes.SETLOCALE,
  payload: locale,
});

export const setStatus = (status: IStoreStatus) => {
  return {
    type: ActionTypes.SETSTATUS,
    payload: status,
  };
};
