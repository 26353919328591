export interface IConfig {
  colorcode: string;
  colordark: string;
  colorlight: string;
  logoURI: string;
  address: Array<string>;
  contact: Array<string>;
  apibaseuri: string;
  appname: string;
  appdomain: string;
  appurl: string;
  statuspage: string;
  support: string;
}

export class Settings implements IConfig {
  public colorcode: string;
  public colordark: string;
  public colorlight: string;
  public logoURI: string;
  public address: Array<string>;
  public contact: Array<string>;
  public apibaseuri: string;
  public appname: string;
  public appdomain: string;
  public appurl: string;
  public statuspage: string;
  public support: string;

  constructor(props: IConfig) {
    this.colorcode = props.colorcode || '';
    this.colordark = props.colordark || '';
    this.colorlight = props.colorlight || '';
    this.logoURI = props.logoURI || '';
    this.address = props.address || '';
    this.contact = props.contact || '';
    this.apibaseuri = props.apibaseuri || '';
    this.appname = props.appname || '';
    this.appdomain = props.appdomain || '';
    this.appurl = props.appurl || '';
    this.statuspage = props.statuspage || '';
    this.support = props.support || '';
  }
}
