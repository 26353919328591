import React, { useState } from 'react';
import { useLoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import MapOptions from './MapOptions';
import { RootState } from '../../store/rootReducer';
import { ISODateStringDate, ISODateStringTime, ISODateString } from '../../utils';
import MarkerSvg from './marker.svg';
import MarkerActiveSvg from './marker_active.svg';
import Button from '../Button';

import Styles from './styles.module.css';

const googleMapsApiKey = 'AIzaSyD9Qxl8D5MzFa37Ne-HANVlaIKb8fzkvjU';

const GoogleMaps = (props) => {
  const geoPositions = props.data;
  const language = useSelector((state: RootState) => state.app.locale);

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [zoom] = useState(15);
  const [center] = useState(props.cockpit && { lat: geoPositions[0].lat, lng: geoPositions[0].lng });
  const [infoOpen, setInfoOpen] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    language: props.language,
    // preventGoogleFontsLoading: true
  });

  // Iterate geoPositions to size, center, and zoom map to contain all markers
  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();

    geoPositions.map((place) => {
      bounds.extend({ lat: place.lat, lng: place.lng });
      return place.MachineName;
    });
    map.fitBounds(bounds);
  };

  const loadHandler = (map) => {
    // Fit map bounds to contain all markers
    fitBounds(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.MachineName]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
  };

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerClassName={Styles.mapContainer}
        options={MapOptions}
        onLoad={!props.cockpit && loadHandler}
        zoom={props.cockpit && zoom}
        center={props.cockpit && center}
      >
        {geoPositions.map((place) => (
          <Marker
            key={place.MachineName}
            title={place.MachineName}
            position={{ lat: place.lat, lng: place.lng }}
            onLoad={(marker) => markerLoadHandler(marker, place)}
            onClick={(event) => markerClickHandler(event, place)}
            icon={place.StringValue === '1' ? { url: MarkerActiveSvg } : { url: MarkerSvg }}
          />
        ))}
        {infoOpen && selectedPlace && (
          <InfoWindow
            options={{ maxHeight: 800 }}
            anchor={markerMap[selectedPlace.MachineName]}
            onCloseClick={() => setInfoOpen(false)}
          >
            <div className={Styles.infoWindow}>
              <h3>{props.data.find((obj) => obj.MachineId === selectedPlace.MachineId).MachineName} </h3>
              <p>
                {ISODateString(
                  new Date(props.data.find((obj) => obj.MachineId === selectedPlace.MachineId).ServerRequestTimeStamp),
                )}
              </p>
              {props.cockpit ? (
                <Button
                  text={`${language === 'fr_FR' ? 'Directions' : language === 'it_IT' ? 'Percorso' : 'Anfahrt'}`}
                  link={props.data.find((obj) => obj.MachineId === selectedPlace.MachineId).GoogleMapsURL}
                  external={true}
                />
              ) : (
                <Button
                  text={`${language === 'fr_FR' ? 'Cockpit' : language === 'it_IT' ? 'Abitacolo' : 'Cockpit'}`}
                  link={`/g/${props.data.find((obj) => obj.MachineId === selectedPlace.MachineId)}`}
                />
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return props.data && isLoaded && renderMap();
};

export default GoogleMaps;
