import * as React from 'react';
import css from './styles.module.css';
import { ISODateStringDate, ISODateStringTime } from '../../utils';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

const BooleanText: React.FC<any> = (props) => {
  const language = useSelector((state: RootState) => state.app.locale);
  const value = props.data.gauge.value;
  const timestamp = props.data.gauge.sourcetimestamp;
  const meta = props.data.meta.mdata;

  const { colordark } = useSelector((state: RootState) => state.app.config);

  return (
    <div className={css.instrument}>
      <svg viewBox="0 0 300 300">
        <circle fill={colordark || '#263d42'} cx="150" cy="150" r="150" />

        <circle
          fill="transparent"
          cx="150"
          cy="150"
          r="130"
          strokeWidth="5"
          stroke={value === 1 ? '#72f887' : '#516468'}
        />
        <line x1="150" y1="10" x2="150" y2="120" strokeWidth="30" stroke={colordark || '#263d42'} />
        <line x1="150" y1="10" x2="150" y2="120" strokeWidth="5" stroke={value === 1 ? '#72f887' : '#516468'} />

        <text className={css.value} fill="#ffffff" transform="translate(150 163)" textAnchor="middle">
          {value === 0
            ? language === 'fr_FR'
              ? meta.BoolFrench0
              : language === 'it_IT'
              ? meta.BoolItalian0
              : meta.BoolGerman0
            : value === 1
            ? language === 'fr_FR'
              ? meta.BoolFrench1
              : language === 'it_IT'
              ? meta.BoolItalian1
              : meta.BoolGerman1
            : null}
        </text>

        <text className={css.timestamp} fill="#ffffff" transform="translate(150 190)" textAnchor="middle">
          <tspan x="0" dy="1.2em">
            {ISODateStringDate(new Date(timestamp))}
          </tspan>
          <tspan x="0" dy="1.2em">
            {ISODateStringTime(new Date(timestamp))}
          </tspan>
        </text>
      </svg>
      <div className={css.instrumentLabel}>
        <h3>{language === 'fr_FR' ? meta.LabelFrench : language === 'it_IT' ? meta.LabelItalian : meta.LabelGerman}</h3>
      </div>
    </div>
  );
};

export default BooleanText;
