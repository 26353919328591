import { produce } from 'immer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionTypes, fetchStatus } from './actions';
import { Action, AppState } from './types';

import config from '../../../../config/config.json';
import { Settings } from '../../../../config/app/config';

// TODO: #126 (https://github.com/vib-gmbh/vib-connect-node/issues/126)
export const initialState = Object.freeze<Readonly<AppState>>({
  config: new Settings(config),
  locale: 'de_DE',
  loggedIn: false,
  status: {
    authorized: false,
    message: '',
    error: '',
    timestamp: null,
  },
});

/*
 * @internal
 * Fix https://github.com/vib-gmbh/vib-connect-node/issues/98
 */
const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStatus.pending, (state: AppState, action: PayloadAction<any>) => {
        state.status = { ...action.payload };
      })
      .addCase(fetchStatus.fulfilled, (state: AppState, action: PayloadAction<any>) => {
        state.status = { ...action.payload };
      })
      .addCase(fetchStatus.rejected, (state: AppState, action: PayloadAction<any>) => {
        state.status = { ...action.payload };
      });
  },
});

export const { setLocale } = statusSlice.actions;
export default statusSlice.reducer;
