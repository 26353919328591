import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { fetchGauges } from '../../store/data/actions';
import { RootState } from 'store/rootReducer';
import { useEffect } from 'react';

import { canUseDOM } from '../../utils';

import Grid from '../../components/Instruments/Grid';
import BooleanText from '../../components/Instruments/BooleanText';
import Gauge from '../../components/Instruments/Gauge';
import StringValueOnly from '../../components/Instruments/StringValueOnly';

import GoogleMaps from '../../components/GoogleMaps';
import css from './styles.module.css';

const Gauges: React.FC = () => {
  const location = useLocation();
  let machine = '';
  const showMachine = location.state?.machine || null;
  const machineName = location.state?.name || 'Machine';
  const latestTS = location.state?.latest || '';

  if (showMachine === null) {
    return '';
  }

  const dispatch = useDispatch();
  const { gauges: state, metricmetadata: meta } = useSelector((state: RootState) => state.data);
  const { isLoading } = useSelector((state: RootState) => state.data);

  let timer = null;
  const ts = Date.now();

  if (state) {
    if (state.timestamp) {
      timer = state.timestamp;
    }
  }

  // TODO: #127 (https://github.com/vib-gmbh/vib-connect-node/issues/127)
  useEffect(() => {
    if (timer === null || ts - timer > 2000) {
      dispatch(fetchGauges(showMachine));
    }
  }, [state, dispatch]);

  let d = [];

  if (state) {
    if (state.gauges) {
      d = state.gauges;
    }
  }

  // TODO: #124 (https://github.com/vib-gmbh/vib-connect-node/issues/124)
  if (isLoading === false) {
    if (state?.error) {
      if (canUseDOM()) {
        if (!window.location.origin) {
          window.location.origin =
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '');
        }

        window.location = `${window.location.origin}/logout`;
      }
    }
  }

  return (
    <React.Fragment>
      <h1 className={css.wrapper}>{machineName}</h1>
      <p className={css.wrapper}>{latestTS}</p>
      <Grid>
        {d.length === 0
          ? ''
          : d.map((g, i) =>
              meta[g.gauge_uuid].mdata.VisualRepresentation === 'BooleanText' ? (
                <BooleanText key={`${g.gauge_uuid}-${i}`} data={{ gauge: g, meta: meta[g.gauge_uuid] }} />
              ) : meta[g.gauge_uuid].mdata.VisualRepresentation === 'Gauge' &&
                meta[g.gauge_uuid].mdata.GaugeScaleMin !== null ? (
                <Gauge key={`${g.gauge_uuid}-${i}`} data={{ gauge: g, meta: meta[g.gauge_uuid] }} />
              ) : meta[g.gauge_uuid].mdata.VisualRepresentation === 'StringValueOnly' ? (
                <StringValueOnly key={`${g.gauge_uuid}-${i}`} data={{ gauge: g, meta: meta[g.gauge_uuid] }} />
              ) : null,
            )}
      </Grid>
      <GoogleMaps data={location.state?.maps || []} cockpit={true} language={'de'} />
    </React.Fragment>
  );
};

export default Gauges;
