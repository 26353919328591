import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RootState } from 'store/rootReducer';
import Logo from '../Logo';
import { findDigits } from '../../utils';

import css from './styles.module.css';

const Footer: React.FC<any> = () => {
  const { t } = useTranslation();
  const { colorcode, colordark, colorlight, logoURI, address, contact } = useSelector(
    (state: RootState) => state.app.config,
  );

  return (
    <footer className={css.footer} style={{ backgroundColor: colordark, color: colorlight }}>
      <Logo logoURI={logoURI} color="#ffffff" />
      <address>
        {address.map((item, index) => (
          <p key={`addrLine-${index}`}>{item}</p>
        ))}
      </address>
      <div>
        <address>
          {contact.map((item, index) => {
            if (item.search('@') != -1) {
              return (
                <a
                  href={`mailto:${item}`}
                  target="_blank"
                  key={`contactLine-${index}`}
                  rel="noreferrer"
                  style={{ color: colorcode }}
                >
                  {item}
                </a>
              );
            }

            if (findDigits(item) > 0 && findDigits(item) < 13) {
              return (
                <a
                  href={`tel:${item}`}
                  target="_blank"
                  key={`contactLine-${index}`}
                  rel="noreferrer"
                  style={{ color: colorcode }}
                >
                  {item}
                </a>
              );
            }

            return <span key={`contactLine-${index}`}>{item}</span>;
          })}
        </address>
        <p>
          <Link
            style={{ color: colorcode }}
            to={{
              pathname: '/p/imprint',
              state: {
                component: 'imprint',
              },
            }}
          >
            {t('imprint')}
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
