export interface isEmptyFnInterface {
  (str?: string): boolean;
}

export interface findDigitsFnInterface {
  (str: string): number;
}

export const isEmpty: isEmptyFnInterface = (str) => !str || str.length === 0;

export const findDigits: findDigitsFnInterface = (str) => {
  const digits = str.match(/\d+/g);
  return (digits && digits.join('').length) || 0;
};

export function ISODateStringDate(d: any) {
  function pad(n: any) {
    return n < 10 ? '0' + n : n;
  }
  return pad(d.getDate()) + '.' + pad(d.getMonth() + 1) + '.' + d.getFullYear();
}

export function ISODateStringTime(d: any) {
  function pad(n: any) {
    return n < 10 ? '0' + n : n;
  }
  return pad(d.getHours()) + ':' + pad(d.getMinutes());
}

export function ISODateString(d: any) {
  function pad(n) {
    return n < 10 ? '0' + n : n;
  }
  return (
    pad(d.getDate()) +
    '.' +
    pad(d.getMonth() + 1) +
    '.' +
    d.getFullYear() +
    ' ' +
    pad(d.getHours()) +
    ':' +
    pad(d.getMinutes())
  );
}

export function DescribeArc(x, y, radius, startAngle, endAngle) {
  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

  return d;
}

export function Scale(num, in_min, in_max) {
  return ((num - in_min) * (120 - -120)) / (in_max - in_min) + -120;
}

export function canUseDOM() {
  return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
}
