import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { fetchInventory } from '../../store/data/actions';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { canUseDOM } from '../../utils';
import Machines from '../../components/Machines';

import css from './styles.module.css';

const Inventory: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.data.inventory);
  const { isLoading } = useSelector((state: RootState) => state.data);

  let timer: any = null;
  const ts = Date.now();

  if (state) {
    if (state.timestamp) {
      timer = state.timestamp;
    }
  }

  useEffect(() => {
    if (timer === null || ts - timer > 30000) {
      dispatch(fetchInventory());
    }
  }, [state, dispatch]);

  // TODO: #124 (https://github.com/vib-gmbh/vib-connect-node/issues/124)
  if (isLoading === false) {
    if (state?.error) {
      if (canUseDOM()) {
        if (!window.location.origin) {
          window.location.origin =
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '');
        }

        window.location = `${window.location.origin}/logout`;
      }
    }
  }

  return (
    <section className={css.wrapper}>
      <h1 className={css.inventory}>{t('inventory.title')}</h1>
      <p className={`${css.captiontitle} bold`}>{t('inventory.captiontitle')}</p>
      <p className={css.caption}>{t('inventory.caption')}</p>
      {state &&
        state.org?.map((org, i) => (
          <div key={`${org.uuid}-${i}`} className={css.inventory}>
            <h2 className={css.orgname} key={`${org.uuid}-${org.id}`}>
              <span>{org.orgname}</span>
            </h2>
            <Machines org={org.uuid} data={state} />
          </div>
        ))}
    </section>
  );
};

export default Inventory;
