import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { routes } from './routes';
import { RootState } from 'store/rootReducer';
import { canUseDOM } from 'utils';

import Admin from './pages/Admin';
import Gauges from './pages/Gauges';
import Inventory from './pages/Inventory';
import Page from './pages/Page';

import Footer from './components/Footer';
import Logo from './components/Logo';
import Loginlogout from './components/Loginlogout';
import Stroke from './components/Stroke';

import css from './App.module.css';
import { setLocale } from './store/app/reducer';

import appleTouchIcon from '../shared/assets/icons/apple-touch-icon.png';
import favicon32 from '../shared/assets/icons/favicon-32x32.png';
import favicon16 from '../shared/assets/icons/favicon-16x16.png';
import safaript from '../shared/assets/icons/safari-pinned-tab.svg';

export const appcolors = {
  red: '#e5004f',
  yellow: '#f2df34',
  green: '#72f887',
};

export const languageOptions = [
  { value: 'de_DE', label: 'DE' },
  { value: 'fr_FR', label: 'FR' },
  { value: 'it_IT', label: 'IT' },
  { value: 'en_US', label: 'EN' },
];

/**
 * Creates the vib connect application
 */

// TODO: Re-enable and fix eslint rule
/* eslint-disable @typescript-eslint/no-explicit-any */
const App: React.FC<any> = () => {
  const {
    config: { colorcode },
    config: { colordark },
    config: { logoURI },
    locale,
  } = useSelector((state: RootState) => state.app);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLocaleChange = useCallback(
    (selected) => {
      dispatch(setLocale(selected.value));
    },
    [dispatch],
  );

  const selectedOption = () => {
    if (!canUseDOM()) {
      return languageOptions[0];
    }

    const browserLang = navigator.language || navigator.userLanguage;

    const knownLang = languageOptions.find((lang) => {
      const browser = browserLang.split('_');
      return lang.label.toLowerCase().indexOf(browser[0]);
    });
    return knownLang || languageOptions[0];
  };

  // TODO: #134 (https://github.com/vib-gmbh/vib-connect-node/issues/134)
  return (
    <div>
      <Helmet
        defaultTitle="VanoTracker"
        titleTemplate="%s – VanoTracker"
        link={[
          { rel: 'apple-touch-icon', sizes: '180x180', type: 'image/png', href: appleTouchIcon },
          { rel: 'icon', sizes: '32x32', type: 'image/png', href: favicon32 },
          { rel: 'icon', sizes: '16x16', type: 'image/png', href: favicon16 },
          { rel: 'mask-icon', color: '#000000', type: 'image/png', href: safaript },
          { rel: 'icon', type: 'image/x-icon', href: '/static/assets/favicon.ico' },
          { rel: 'manifest', href: '/static/assets/site.webmanifest' },
        ]}
        meta={[
          { name: 'msapplication-TileColor', content: '#ffffff' },
          { name: 'theme-color', content: '#ffffff' },
        ]}
      />
      <Stroke color={colorcode} />
      <header className={css.header}>
        <Logo logoURI={logoURI} color={colordark} />
        <div className={css.nav}>
          <nav>
            <ul>
              <li>
                <Link to={`/i/inventory`} style={{ color: colordark }}>
                  {t('nav.inventory')}
                </Link>
              </li>
            </ul>
          </nav>
          <div className={css.lang}>
            <Select
              defaultValue={selectedOption()}
              onChange={handleLocaleChange}
              options={languageOptions}
              styles={{ control: (style) => ({ ...style, border: 0, width: 80 }) }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: colorcode,
                },
              })}
            />
          </div>
          <Loginlogout />
        </div>
      </header>
      <main className={css.wrapper}>
        <Gauges />
        <Switch>
          <Route exact path={routes.admin} component={Admin} />
          <Route exact path={routes.inventory} component={Inventory} />
          <Route exact path={routes.gauges} conponent={Gauges} />
          <Route exact path={routes.page} component={Page} />
          <Route exact path={routes.home} component={Page} />
        </Switch>
      </main>

      <Footer />
    </div>
    // </Suspense>
  );
};

export default App;
