import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from 'store/rootReducer';

const Imprint: React.FC = () => {
  const { t } = useTranslation();
  const { address, contact } = useSelector((state: RootState) => state.app.config);

  let keyIndex = 0;
  const mapper = (items: Array<any>) =>
    items.map((item: string, i: number) => {
      keyIndex += i;
      return <p key={`imprint-line-${keyIndex}`}>{item}</p>;
    });

  return (
    <React.Fragment>
      <h1>{t('imprint')}</h1>
      {mapper(address)}
      {mapper(contact)}
    </React.Fragment>
  );
};

export default Imprint;
