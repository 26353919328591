import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../store/rootReducer';
import Status from '../Status';
import css from './styles.module.css';

const Welcome: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  const { statuspage, support, appname } = useSelector((state: RootState) => state.app.config);

  return (
    <section className={css.inner}>
      <h1>
        {t('welcome.title')} {appname}
      </h1>
      <Status />
      <p>
        {t('welcome.status')}{' '}
        <a href={`${statuspage}`} target={'_blank'} rel="noreferrer">
          {statuspage}
        </a>
        .
      </p>
      <p>
        {t('welcome.support')} {support}
      </p>
    </section>
  );
};

export default Welcome;
