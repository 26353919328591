import React, { useState, useRef, useEffect } from 'react';

import { isEmpty } from '../../utils';
import css from './styles.module.css';

import { ReactComponent as Icon } from '../../../../config/logo.svg';

interface LogoProps {
  logoURI?: string;
  color?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ logoURI, color }) => {
  // TODO: https://github.com/vib-gmbh/vib-connect-node/issues/84
  if (isEmpty(logoURI)) {
    return (
      <a href={'/'}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className={`${css.logo} ${css.defaultColor}`}>
          <path
            stroke="none"
            fill={''}
            d="M 88.95,3.43 C 89.21,3.71 89.6,3.85 90.12,3.85 90.63,3.85 91.02,3.71 91.28,3.43 91.54,3.15 91.67,2.8 91.67,2.38 91.67,1.95 91.54,1.59 91.28,1.3 91.02,1 90.63,0.85 90.12,0.85 89.6,0.85 89.21,1 88.95,1.29 88.69,1.58 88.56,1.95 88.56,2.37 88.56,2.8 88.69,3.15 88.95,3.43 Z M 83.75,8.61 L 88.9,8.61 88.9,19.26 83.75,19.26 83.75,21.5 96.43,21.5 96.43,19.26 91.49,19.26 91.49,6.36 83.75,6.36 83.75,8.61 Z M 68.3,6.36 L 65.64,6.36 71.87,21.5 73.85,21.5 80.02,6.36 77.37,6.36 73.13,17.78 72.88,18.72 72.64,17.78 68.3,6.36 Z M 112.99,10.69 C 112.73,9.74 112.35,8.93 111.84,8.25 111.34,7.57 110.71,7.04 109.96,6.65 109.21,6.27 108.35,6.08 107.38,6.08 106.45,6.08 105.63,6.24 104.93,6.57 104.23,6.9 103.63,7.37 103.14,7.98 L 103.14,-0 100.55,-0 100.55,21.5 102.93,21.5 103.06,19.78 C 103.56,20.42 104.17,20.92 104.89,21.26 105.6,21.61 106.45,21.78 107.42,21.78 108.38,21.78 109.23,21.58 109.98,21.19 110.72,20.8 111.34,20.26 111.85,19.57 112.35,18.88 112.74,18.06 113,17.13 113.26,16.19 113.39,15.18 113.39,14.1 L 113.39,13.8 C 113.38,12.68 113.25,11.64 112.99,10.69 Z M 110.79,14.1 C 110.79,14.84 110.71,15.54 110.56,16.2 110.41,16.86 110.17,17.45 109.85,17.95 109.53,18.45 109.11,18.85 108.6,19.15 108.09,19.44 107.48,19.59 106.77,19.59 106.32,19.59 105.91,19.53 105.54,19.41 L 105.55,19.41 C 105.19,19.3 104.86,19.14 104.56,18.93 104.23,18.7 103.97,18.45 103.74,18.16 103.51,17.88 103.31,17.56 103.15,17.23 L 103.15,10.61 C 103.31,10.27 103.5,9.96 103.73,9.68 103.96,9.4 104.22,9.15 104.51,8.94 104.8,8.73 105.14,8.57 105.51,8.45 105.88,8.33 106.29,8.27 106.74,8.27 107.49,8.27 108.12,8.42 108.63,8.72 109.14,9.02 109.56,9.42 109.88,9.93 110.2,10.44 110.43,11.03 110.57,11.69 110.71,12.36 110.79,13.06 110.79,13.8 L 110.79,14.1 Z M 110.79,14.1"
          />
          <path
            stroke="none"
            fill={''}
            d="M 45.44,24.03 C 39.87,24.03 35.34,19.5 35.34,13.93 35.34,8.36 39.87,3.83 45.44,3.83 51.01,3.83 55.54,8.36 55.54,13.93 55.54,19.5 51.01,24.03 45.44,24.03 Z M 45.44,5.33 C 40.7,5.33 36.84,9.19 36.84,13.93 36.84,18.67 40.7,22.53 45.44,22.53 50.18,22.53 54.04,18.67 54.04,13.93 54.04,9.19 50.19,5.33 45.44,5.33 Z M 32.07,14.68 L 0.75,14.68 C 0.34,14.68 0,14.34 0,13.93 0,13.52 0.34,13.18 0.75,13.18 L 32.07,13.18 C 32.48,13.18 32.82,13.52 32.82,13.93 32.82,14.34 32.48,14.68 32.07,14.68 Z M 32.07,14.68"
          />
        </svg>
      </a>
    );
  } else {
    return (
      <a href={'/'}>
        <Icon fill={color || ''} className={`${css.logo}`} />
      </a>
    );
  }
};

export default Logo;
