import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { fetchStatus } from '../../store/app/actions';
import { appcolors } from '../../App';

import css from './styles.module.css';

// TODO: #125 (https://github.com/vib-gmbh/vib-connect-node/issues/125)
const Loginlogout = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.app.status);

  useEffect(() => {
    const ts = Date.now();
    if (state.timestamp === null || ts - state.timestamp > 10000) {
      dispatch(fetchStatus());
    }
  }, [state, dispatch]);

  const text = (state.authorized && 'Logout') || 'Login';
  const style = (state.authorized && {
    color: '#ffffff',
    backgroundColor: appcolors.red,
  }) || {
    color: '#ffffff',
    backgroundColor: appcolors.green,
  };

  return (
    <a
      href={(state.authorized && '/logout') || '/login'}
      title={`Use this button to ${text}.`}
      className={css.loginlogout}
      style={style}
    >
      {text}
    </a>
  );
};

export default Loginlogout;
