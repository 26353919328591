import * as React from 'react';
import css from './styles.module.css';

const Grid: React.FC<any> = (props) => {
  return (
    <div className={css.gridWrapper}>
      <div className={css.grid}>{props.children}</div>
    </div>
  );
};

export default Grid;
