import React, { useEffect } from 'react';
import { useCountUp } from 'react-countup';
import { ISODateStringTime, ISODateStringDate } from '../../utils';
import css from './styles.module.css';

import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const StringValueOnly: React.FC<any> = (props) => {
  const { colordark } = useSelector((state: RootState) => state.app.config);

  const meta = props.data.meta.mdata;
  const value = props.data.gauge.value;
  const timestamp = props.data.gauge.sourcetimestamp;
  const language = useSelector((state: RootState) => state.app.locale);

  const { colorcode } = useSelector((state: RootState) => state.app.config);

  const countUpValue = Number(Number(value).toFixed(meta.DecimalAccuracy));

  const { countUp, update } = useCountUp({
    decimals: meta.DecimalAccuracy,
    end: countUpValue,
    duration: 0.15,
  });

  useEffect(() => {
    let isCanceled = false;

    if (isCanceled) {
      return;
    }

    if (countUpValue !== countUp) {
      if (!isCanceled) {
        update(countUpValue);
      }
    }

    return () => {
      isCanceled = true;
    };
  }, [countUpValue, countUp, update]);

  return (
    <div className={css.instrument}>
      <svg viewBox="0 0 300 300">
        <circle fill={colordark || '#263d42'} cx="150" cy="150" r="150" style={{ stroke: colorcode }} />

        <line style={{ stroke: colorcode }} x1="13" y1="150" x2="287" y2="150" strokeWidth="60" stroke="#72f887" />

        <circle
          style={{ stroke: `darken(${colorcode}, 8%)` }}
          fill="transparent"
          cx="150"
          cy="150"
          r="137"
          strokeWidth="26"
          stroke={colordark || '#263d42'}
        />

        <text className={css.value} fill="#263d42" transform="translate(150 163)" textAnchor="middle">
          {countUp}
        </text>

        <text className={css.unit} fill="#ffffff" transform="translate(150 86)" textAnchor="middle">
          {meta.Unit}
        </text>

        <text className={css.timestamp} fill="#ffffff" transform="translate(150 198)" textAnchor="middle">
          <tspan x="0" dy="1.2em">
            {ISODateStringDate(new Date(timestamp))}
          </tspan>
          <tspan x="0" dy="1.2em">
            {ISODateStringTime(new Date(timestamp))}
          </tspan>
        </text>
      </svg>
      <div className={css.instrumentLabel}>
        <h3>{language === 'fr_FR' ? meta.LabelFrench : language === 'it_IT' ? meta.LabelItalian : meta.LabelGerman}</h3>
      </div>
    </div>
  );
};

export default StringValueOnly;
