import React from 'react';
// import { ReactComponent } from '*.svg';
// import { useDispatch } from 'react-redux';
// import { ReactComponent } from '*.svg';

const Admin: React.FC<any> = () => (
  <React.Fragment>
    <h2>Admin Page, hello</h2>
    <p>This Page should be protected!</p>
  </React.Fragment>
);

export default Admin;
